import * as React from 'react';
import {
    Button,
    Typography,
    Row,
} from "antd";

import {graphql} from "gatsby";
import Img from 'gatsby-image';
import {TopLogo} from "../../components/logo/TopLogo";
import {Footer} from "../../components/footer/Footer";
import {
    GithubOutlined
} from "@ant-design/icons";
import Gitalk from 'gatsby-plugin-gitalk';

import '/src/styles/global.less';
import '/src/styles/markdown-style.less';
import '@suziwen/gitalk/dist/gitalk.css'
import {UtterancesComment} from "../../components/comment/UtterancesComment";
import {DefaultLayout} from "../../components/layout/DefaultLayout";
import {useLocation} from "@reach/router";

const {Title} = Typography;

const PostsPage = ({
                       data,
                   }) => {
    const {pathname, origin} = useLocation();

    const {markdownRemark, site:{siteMetadata}} = data;
    const {frontmatter, html, excerpt} = markdownRemark;

    return (
        <DefaultLayout seo={{
            title: frontmatter.title,
            description: excerpt,
            pathname: pathname,
            image: siteMetadata.siteUrl + frontmatter.featuredImage.childImageSharp.fluid.src,
            meta: [],
        }}>
            <section className="blog-container">
                <Title>
                    {frontmatter.title}
                </Title>
                <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} style={{
                    display: 'block',
                    position: 'relative',
                    width: '100%'
                }}/>
            </section>
            <section className="blog-container" style={{
                marginTop: 60,
            }}>
                <div className="blog-post-content markdown"
                     dangerouslySetInnerHTML={{__html: html}}>

                </div>
                {
                    frontmatter.github &&
                    <Row justify="center" style={{
                        marginTop: 40
                    }}>
                        <Button
                            type="primary"
                            color="primary"
                            size="large"
                            onClick={() => window.open(frontmatter.github)}
                            icon={<GithubOutlined/>}
                        >
                            GitHub 링크 바로가기
                        </Button>
                    </Row>
                }
                <UtterancesComment/>
            </section>
        </DefaultLayout>
    )
};

export const pageQuery = graphql`
    query($slug: String!){
        site {
            siteMetadata {
                siteUrl
            }
        }
        markdownRemark(fields:{slug:{eq:$slug}}){
            html
            fields{
                slug
            }
            excerpt
            frontmatter{
                title
                featuredImage{
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                github
                tags
                updatedAt
                createdAt
            }
        }
    }
`

export default PostsPage;

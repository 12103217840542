import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const commentNodeId = 'comments';

export const UtterancesComment = () => {
    // this query is for retrieving the repo name from gatsby-config
    const data = useStaticQuery(graphql`
        query RepoQuery {
            site {
                siteMetadata {
                    commentRepo
                }
            }
        }
    `);

    React.useEffect(() => {
        // docs - https://utteranc.es/
        const script = document.createElement('script');
        script.src = 'https://utteranc.es/client.js';
        script.async = true;
        script.setAttribute('repo', data.site.siteMetadata.commentRepo);
        script.setAttribute('issue-term', 'url');
        script.setAttribute('label', '✨💬✨');
        script.setAttribute(
            'theme',
            'github-light',
        );
        script.setAttribute('crossorigin', 'anonymous');

        const scriptParentNode = document.getElementById(commentNodeId);
        scriptParentNode.appendChild(script);

        return () => {
            scriptParentNode.removeChild(scriptParentNode.firstChild);
        };
    }, [data]);

    return <div id={commentNodeId} />;
};
